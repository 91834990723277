
import './carousel.css';
import './background.css';
import Screenshot from "./Screenshot.png";
import Tommy from "./Tommy.png";
import Power from "./Power.png";
import Calculator from "./Calculator.png";
import Clock from "./Clock.png";
import DrumMachine from "./DrumMachine.png";
import ArtGenerator from "./ArtGenerator.png";
import MarkdownPreviewer from "./MarkdownPreviewer.png";
import FrontEnd from "./FrontEndCert.png";
import JSAlgo from "./JSAlgorithms.png";
import Pfp from "./pfp.jpeg"
import './App.css';
import { motion, useViewportScroll } from "framer-motion";
//import useEmblaCarousel from 'embla-carousel-react'
import EmblaCarousel from './EmblaCarousel';

let projects = [{ name: "Alpha One Communications", img: Screenshot, address: "https://alphaonecommunications.com" }, { name: "Haughton Legal - Attorney At Law", img: Tommy, address: "https://en.haughtonlegal.com" }, { name: "Power Exterminators", img: Power, address: "https://powerexterminators.net" }, { name: "Javascript Calculator", img: Calculator, address: "https://290m7p.csb.app/" }, { name: "25 + 5 Clock", img: Clock, address: "https://r56ykd.csb.app/" }, { name: "Markdown Previewer", img: MarkdownPreviewer, address: "https://sg6b7.csb.app/" }, { name: "Random Quote Machine", img: ArtGenerator, address: "https://ilpyx.csb.app/" }, { name: "Drum Machine", img: DrumMachine, address: "https://ls3rdw.csb.app/" }]
let certs = [{ id: "front", name: "Front End Development Libraries", img: FrontEnd, address: "https://freecodecamp.org/certification/eliecerthoms/front-end-development-libraries" }, { id: "jsAlgo", name: "JavaScript Algorithms and Data Structures", img: JSAlgo, address: "https://www.freecodecamp.org/certification/eliecerthoms/javascript-algorithms-and-data-structures" }]
function App() {
  //const [emblaRef] = useEmblaCarousel()

  //const { scrollYProgress } = useViewportScroll()
  return (
    <div id="App">
      <div id="hero" className='background--custom'>
        <motion.div
          initial={{ y: -450 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.4, type: 'spring', stiffness: 120 }}

          className="padding">
          <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.2 }} src={Pfp} alt="" id="profile" />
          <motion.h1 animate={{ fontSize: 100 }} id="headline">Hello, I'm Eli.</motion.h1>
          <motion.h2>Nice to meet you. </motion.h2>
        </motion.div>
      </div>
      <div id="about-me" className='padding'>
        <h1>About Me</h1>
        <p id="about-me-desc">Highly driven and motivated. Diligent provider of solutions tailored to your wants and needs.
          Skilled in Javascript-based full-stack web development along with many other tools and ancillary technologies to make your ideas come to life reliably and with scalability in mind. At your service.</p>
      </div>
      <div id="skills" className='padding'>
        <div id="left">

          <h1>Skills</h1>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>Javascript</li>
            <li>React</li>
            <li>Node.js</li>
            <li>PostgreSQL</li>

          </ul>
        </div>
        <div id="right">

          <h1 >Qualities</h1>
          <ul>
            <li>Self-driven, self-starter</li>
            <li>Big-picture Thinker</li>
            <li>Knack for Solving Problems</li>
            <li>Quick-learner</li>
            <li>Adaptable to all requirements</li>
            <li>Willingness to persevere</li>
          </ul>
        </div>
      </div>
      <div id="projects" className='padding'>
        <h1>Published Websites and Javascript Front-End Projects</h1>
        <EmblaCarousel slides={projects}></EmblaCarousel>
      </div>
      <h1 className='padding'>Certifications</h1>
      <div id="certifications" className='padding'>
        {certs.map(cert => {
          return (
            <motion.a
              whileHover={{ scale: 1.02, originX: 0.5 }}

              href={cert.address} id={cert.id} >
              <figure className="certificate">
                <img src={cert.img} alt="" />
                <figcaption className="certLink">{cert.name}</figcaption>
              </figure>
            </motion.a>
          )
        })
        }
      </div>
      <h1 className='padding'>Contact </h1>
      <p className='padding' id="contact-msg">I am thrilled to build something great together, you can get in touch with me at <a style={{color:"white"}} href='mailto:eliecerthoms@proton.me'>eliecerthoms@proton.me</a>!</p>
      
    </div >
  );
}

export default App;
